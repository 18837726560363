import errorHandler from './handler/erroHandler';
import axiosInstance from './manager/manager.service';

export const animateurService = {
    create,
    update,
    get,
    del
};

async function create(data) {

    try {
        let ret = await axiosInstance.post(`/animateurs`, data);

        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function get() {

    try {
        let ret = await axiosInstance.get(`/animateurs`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}

async function update(data, id) {
    try {
        let ret = await axiosInstance.put(`/animateurs/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


async function del(id) {
    try {
        let ret = await axiosInstance.delete(`/animateurs/${id}`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


