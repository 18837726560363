<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import creatingButton from "@/components/creatingBtn";
import { toastUtils } from "../../../utils/toast.utils";
import { animateurService } from "../../../services/animateur.service";

export default {
    page: {
        title: "Animateur",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, creatingButton },
    data() {
        return {
            title: "Gestion des animateurs",
            isAdd: false,
            isUpdate: false,
            isNotValid: false,
            filesBaseUrl: process.env.VUE_APP_FILE_URL,
            titleModel: !this.isUpdate ? 'Enregistrement' : 'Mise à jours',
            items: [
                {
                    text: "Programmes",
                    href: "/"
                },
                {
                    text: "Animateur",
                    active: true
                }
            ],
            animateurData: [],
            currentPage: 1,
            totalRows: 1,
            perPage: 5,
            pageOptions: [5, 10, 25, 50],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: false,
            fields: [
                { key: "image", sortable: true, label: "Image" },
                { key: "nom", sortable: true, label: "Nom" },
                { key: "alias", sortable: true, label: "Alias" },
                { key: "isAlias", sortable: true, label: "Affihage" },
                { key: "action" }
            ],
            animateurForm: {
                nom: "",
                prenom: "",
                alias: "",
                isAlias: true,
            },
            submitted: false,
            imageAnimateur: null

        };
    },
    validations: {
        animateurForm: {
            nom: { required },
            prenom: { required },

        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.animateurData.length;
        },
       
    },
    mounted() {
        this.getAnimateurs();
        this.totalRows = this.animateurData.length;

    },
    methods: {

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        showAddForm(show) {
            this.isAdd = show;
            if (!show) {
                this.clearForm();
                this.submitted = false;
                this.isUpdate = false;
            }
        },

        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                this.$bvModal.show('modal-center');
            }
        },

        async formValidate() {
            if (this.animateurForm.isAlias && this.animateurForm.alias == "") {
                toastUtils.error(this.$bvToast, 'Enregistrement', 'Vous devez entrer un alias si vous choisissez d`\'afficher l\'alias');
                return;
            }
            let animateurF = new FormData();
            animateurF.append("nom", this.animateurForm.nom);
            animateurF.append("prenom", this.animateurForm.prenom);
            animateurF.append("alias", this.animateurForm.alias);
            animateurF.append("isAlias", this.animateurForm.isAlias);
            if (this.imageAnimateur != null) animateurF.append("image", this.imageAnimateur.file);

            let resp = this.isUpdate ? await animateurService.update(animateurF, this.animateurForm.id) : await animateurService.create(animateurF);

            if (resp >= 400) {
                toastUtils.error(this.$bvToast, 'Enregistrement', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                this.clearForm();
                this.isAdd = false;
                toastUtils.success(this.$bvToast, 'Enregistrement', 'Enregistrement reussi');
                await this.getAnimateurs();
            }
        },

        async getAnimateurs() {
            this.animateurData = await animateurService.get();
        },

        clearForm() {
            this.$v.$reset();
            this.animateurForm.nom = '';
            this.animateurForm.prenom = '';
            this.animateurForm.alias = '';
            this.animateurForm.isAlias = false;
            this.imageAnimateur = null;
        },

        showUpdateForm(row) {
            this.animateurForm.nom = row.nom;
            this.animateurForm.prenom = row.prenom;
            this.animateurForm.alias = row.alias;
            this.animateurForm.isAlias = row.isAlias;
            this.animateurForm.id = row.id;
            this.isAdd = true;
            this.isUpdate = true;
        },
        showDeleteModal(item) {
            this.deleteId = item.id;
            this.$bvModal.show('delete-modal-center');

        },
        async deleteData() {

            let resp = await animateurService.del(this.deleteId);

            if (resp >= 400) {
                toastUtils.error(this.$bvToast, 'Suppression', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                await this.getAnimateurs();
            }
            this.deleteId = null;
        },
        fileDeleted: function (fileRecord) {
            var i = this.imageAnimateur.indexOf(fileRecord);
            if (i !== -1) {
                this.imageAnimateur.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <creatingButton v-if="!isAdd" :title="'Ajouter'" @click.native="showAddForm(true)" />
        <br>
        <div v-if="isAdd" class="card">
            <div class="card-header">Ajouter un animateur</div>
            <div class="col-md-12  card-body">
                <form class="row needs-validation" @submit.prevent="formSubmit">

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="validationCustom01">Nom</label>
                            <input id="validationCustom01" v-model="animateurForm.nom" type="text"
                                class="form-control" placeholder="Nom"
                                :class="{ 'is-invalid': submitted && $v.animateurForm.nom.$error }" />
                            <div v-if="submitted && $v.animateurForm.nom.$error" class="invalid-feedback">
                                <span v-if="!$v.animateurForm.nom.required">Ce champs est requis.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="validationCustom02">Prénom</label>
                            <input id="validationCustom02" v-model="animateurForm.prenom" type="text" class="form-control"
                                placeholder="Prénom" :class="{ 'is-invalid': submitted && $v.animateurForm.prenom.$error }" />
                            <div v-if="submitted && $v.animateurForm.prenom.$error" class="invalid-feedback">
                                <span v-if="!$v.animateurForm.prenom.required">Ce champs est requis.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="validationCustom02">Alias de l'animateur</label>
                            <input id="validationCustom02" v-model="animateurForm.alias" type="text" class="form-control"
                                placeholder="Alias" />
                        </div>
                    </div>
                   <div class="col-md-6">
                    <div class="form-group">
                        <label class="mt-3" for="validationCustom02">Afficher l'alias</label>
                         <b-form-checkbox
                            v-model="animateurForm.isAlias"
                            name="check-button"
                            switch
                            >
                        </b-form-checkbox>
                    </div>
                   </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Image</label>
                            <VueFileAgent 
                                @delete="fileDeleted($event)"
                                v-model="imageAnimateur"
                                :multiple="false"
                                :deletable="true"
                                :accept="'image/png, image/jpeg, image/jpg'"
                                :maxSize="'3MB'"
                                :maxFiles="1"
                                :helpText="'Sélectionner l\'image'"
                                :errorText="{
                                    type: 'Type de fichier invalid',
                                    size: 'Votre fichier ne peux excéder 3Mo',
                                }">
                            </VueFileAgent>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <b-button variant="primary" @click="formSubmit">
                            <template>
                                <span v-if="isAdd && !isUpdate">Enregistrer</span>
                                <span v-if="isAdd && isUpdate">Modifier</span>
                            </template>
                        </b-button>
                        <button class="btn btn-secondary ml-2" @click="showAddForm(false)">Annuler</button>
                    </div>
                    <b-modal id="modal-center" centered :title=titleModel title-class="font-18" @ok="formValidate">
                        <p>
                            Confirmez-vous l'action?
                        </p>
                    </b-modal>
                </form>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                LISTE DES ANIMATEURS
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    Voir&nbsp;
                                    <b-form-select v-model="perPage" size="sm"
                                        :options="pageOptions"></b-form-select>&nbsp;resultats
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                    Recherche:
                                    <b-form-input v-model="filter" type="search"
                                        class="form-control form-control-sm ml-2"></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                    <div class="table-responsive">
                        <b-table :items="animateurData" :fields="fields" responsive="sm" :per-page="perPage"
                            :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                            :filter-included-fields="filterOn" @filtered="onFiltered" show-empty
                            :empty-text="'Aucun resultat'" :empty-filtered-text="'Aucun resultat'">
                            <template v-slot:cell(action)="row">
                               
                                <a href="javascript:void(0);" @click="showUpdateForm(row.item)" class="mr-3 text-primary"
                                    v-b-tooltip.hover data-toggle="tooltip" title="Modifier">
                                    <i class="mdi mdi-pencil font-size-18"></i>
                                </a>
                                <a href="javascript:void(0);" @click="showDeleteModal(row.item)" class="text-danger"
                                    v-b-tooltip.hover title="Supprimer">
                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                            </template>
                            <template v-slot:cell(image)="r">
                                <img class="rounded-circle" :src="`${filesBaseUrl}/animateur/${r.item.image}`" height="50" alt="">
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows"
                                        :per-page="perPage"></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="delete-modal-center" centered :title="'Supression'" title-class="font-18" @ok="deleteData">
            <p>
                Confirmez-vous l'action?
            </p>
    </b-modal>
</Layout></template>
